import React from 'react';
import {Routes, Route} from "react-router-dom";

// <----- asset start ----------->
// ----css start---->
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../src/css/style.css';
import '../src/css/media_query.css';
// ----css end---->

// ----js start---->
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// ----js end---->
// <----- asset end ----------->

// <----- section components start ----------->
import Home from "./pages/Home";
import BuyLEOSStep from "./pages/BuyLEOSStep";
import PaymentSuccessful from "./pages/PaymentSuccessful";
// <----- section components end ----------->


export default function App() {

  return (
    <>
    	<Routes>
      		<Route path='/' Component={Home} />
      		<Route path='buyleosstep' Component={BuyLEOSStep} />
      		<Route path='paymentsuccessful' Component={PaymentSuccessful} />
    	</Routes>
    </>
  ); 
};
