import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './section_components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<>
		<BrowserRouter>
			<ScrollToTop/>
			<App />
		</BrowserRouter>
  	</>
);

reportWebVitals();
