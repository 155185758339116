import React from 'react';

// <------- section components start --------->
import StepBanner from "./StepBanner";
// <------- section components end --------->

// <------- img start --------->
import StepbannerImgItem from "../../img/signcontract-step-banner.jpg";
// <------- img end --------->

export default function TokenAgreementStep(props) {



    return(
        <>
            <div className='tokenagreement-step-block'>
            <StepBanner
                StepHeading= "Sign the document." 
                MainHeading= "LEOS token sale contract" 
                Textdescription= {["This contract outlines the rights and responsibilities of you and the Tonomy Foundation. This document complies with European Unions upcoming MiCA regulation to ensure the Tonomy Foundation can reliably build Pangea."]}
                StepBtnUrl= "/"
                StepBtnName= "Go to DocuSign to sign the document"
                HelpBtnUrl= "https://pangea-web4-world.webflow.io/contact-us"
                helpBtnName= "Help"
                StepbannerImg= {StepbannerImgItem}
            />
            </div>
       
        </>
    )

}