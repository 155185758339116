import React from "react";

export default function TokenSaleInfo(props) {

    return(
        <>
            <div className="tokensale-info-box d-flex flex-column gap-2">
                <p className="title text-capitalize">{props.TokeninfoTitle}</p>
                <span>{props.TokeninfoDetails}</span>
            </div>
        </>
    )

}