import React from 'react';


export default function SaleRoundTable(props) {

    return (
        <>
            <tr>
                <td>{props.Title}</td>
                <td>{props.StartDate}</td>
                <td>{props.Fdv}</td>
                <td>{props.Price}</td>
                <td>{props.Allocation}</td>
            </tr>
        </>
    )

};