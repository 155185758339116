import React from 'react';
import { Link } from "react-router-dom";

// <------- section components start --------->
import Header from "../section_components/Header";
import Footer from "../section_components/Footer";
// <------- section components end --------->

// <------- img start --------->
import paymentsuccessfullImg from "../img/payment_successful.jpg";
// <------- img end --------->


export default function PaymentSuccessful() {

    return(
        <>
            <div className="main-wapper paymentsuccessfull-page-wapper">
                <Header/>
                    <div className='content-wapper  d-flex flex-column justify-content-center flex-grow-1 two-space'>
                        <div className='container'>
                            <div className='row paymentsuccessfull-wapper g-sm-5 g-4 align-items-center'>
                                <div className='col-lg-6 col-md-8 col-12'>
                                    <div className='caption-box d-flex flex-column'>
                                        <div className='label-box d-flex flex-column align-items-center text-center gap-3'>
                                            <div className='icon-bar'>
                                                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="60" height="60" rx="30" fill="#4CAF50"/>
                                                    <rect x="0.5" y="0.5" width="60" height="60" rx="30" stroke="#4CAF50"/>
                                                    <path d="M26.0041 35.4141L20.082 29.492L17.5391 32.0349L26.0041 40.5L43.4612 23.0429L40.9183 20.5L26.0041 35.4141Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <h3 className='text-capitalize Forest_Green_text'>payment successful</h3>
                                        </div>
                                        <div className='body-box d-flex flex-column gap-sm-4 gap-3'>
                                            <ul className='amount-listing d-flex flex-column m-0 p-0 gap-3'>
                                                <li className='d-flex flex-sm-row flex-column gap-2 justify-content-between align-items-center'>
                                                    <span className='label'>Total Amount Paid</span>
                                                    <p className='price number-text'>1000.00 AUD</p>
                                                </li>
                                            </ul>
                                            <div className='text-caption d-flex flex-column gap-1 align-items-center text-center'>
                                                <p>After your payment is processed</p>
                                                <p>you will receive LEOS in your Pangea account:</p>
                                                <span className='number-text'>1000.00Ƚ</span>
                                            </div>
                                            <div className='btn-bar'>
                                                <Link to="/" className="fill-btn d-flex flex-grow-1 Ocean_Blue_bg allign-items-center gap-1">
                                                    <span>Check Here</span>
                                                    <div className='icon-bar'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.31282 8.31286C6.67187 9.9538 5.75 12.1794 5.75 14.5C5.75 16.8207 6.67187 19.0463 8.31282 20.6872C9.95376 22.3282 12.1794 23.25 14.5 23.25C16.8206 23.25 19.0462 22.3282 20.6872 20.6872C22.3281 19.0463 23.25 16.8207 23.25 14.5C23.25 12.1794 22.3281 9.9538 20.6872 8.31286C19.0462 6.67192 16.8206 5.75005 14.5 5.75005C12.1794 5.75005 9.95376 6.67192 8.31282 8.31286ZM21.5711 21.5711C19.6957 23.4465 17.1522 24.5 14.5 24.5C11.8478 24.5 9.3043 23.4465 7.42893 21.5711C5.55357 19.6957 4.5 17.1522 4.5 14.5C4.5 11.8479 5.55357 9.30434 7.42893 7.42898C9.3043 5.55361 11.8478 4.50005 14.5 4.50005C17.1522 4.50005 19.6957 5.55361 21.5711 7.42898C23.4464 9.30434 24.5 11.8479 24.5 14.5C24.5 17.1522 23.4464 19.6957 21.5711 21.5711ZM10.1257 15.0808C9.95981 15.0809 9.8007 15.0151 9.68334 14.8979C9.56599 14.7807 9.49999 14.6217 9.49987 14.4559C9.49975 14.29 9.56553 14.1309 9.68272 14.0135C9.79991 13.8962 9.95892 13.8302 10.1248 13.8301L17.3664 13.8309L14.9198 11.3844C14.8026 11.2671 14.7368 11.1082 14.7368 10.9424C14.7368 10.7767 14.8026 10.6177 14.9198 10.5005C15.0371 10.3833 15.196 10.3174 15.3618 10.3174C15.5275 10.3174 15.6865 10.3833 15.8037 10.5005L19.3172 14.0139C19.4344 14.1311 19.5002 14.2901 19.5002 14.4559C19.5002 14.6216 19.4344 14.7806 19.3172 14.8978L15.8037 18.4112C15.6865 18.5284 15.5275 18.5943 15.3618 18.5943C15.196 18.5943 15.0371 18.5284 14.9198 18.4112C14.8026 18.294 14.7368 18.135 14.7368 17.9693C14.7368 17.8035 14.8026 17.6446 14.9198 17.5273L17.3664 15.0808L10.1257 15.0808Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-4 col-12 step-banner-imgblock'>
                                    <div className='step-banner-imgbar'>
                                        <img src={paymentsuccessfullImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </div>
        </>
    )

}