import React, { useState } from 'react';
// import { useLocation } from "react-router-dom";

// <------- section components start --------->
import Header from "../section_components/Header";
import Footer from "../section_components/Footer";
import StepStructure from "../section_components/buyleos_step/StepStructure";
import NationalityStep from "../section_components/buyleos_step/NationalityStep";
import LoginStep from "../section_components/buyleos_step/LoginStep";
import IdentityVerificationStep from "../section_components/buyleos_step/IdentityVerificationStep";
import TokenAgreementStep from "../section_components/buyleos_step/TokenAgreementStep";
import PaymentStep from "../section_components/buyleos_step/PaymentStep";
import UseDocumentTitle from "../section_components/UseDocumentTitle";
// <------- section components end --------->



export default function BuyLEOSStep() {

    UseDocumentTitle('Buy LEOS Step');


    var [ activeStep, setActiveStep ] = useState(0);

    var steps = [
        { label: 'nationality'  },
        { label: 'Login' },
        { label: 'Identity Verification' },
        { label: 'Token sale agreement' },
        { label: 'Payment' },
    ];

    
    
    function getSectionComponent() {
        switch(activeStep) {
            case 0: return <NationalityStep/>;
            case 1: return <LoginStep />;
            case 2: return <IdentityVerificationStep />;
            case 3: return <TokenAgreementStep />;
            case 4: return <PaymentStep />;
            default: return null;
        }
    }

    const ScrollTopBtn = () => {
        window.scrollTo(0, 0);
    }   

    function NextStepBtn(){
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
    }

    function PrevStepBtn(){
        setActiveStep(activeStep - 1);
         window.scrollTo(0, 0);
    }
    
    return(
        <>
            <div className="main-wapper buyleosstep-page-wapper">
                <Header/>
                <div className="stepform-wapper">
                    <div className="container">
                        <div className="stepform-block d-flex flex-column">
                            <div className="stepform-progress">
                                <StepStructure
                                    steps={steps}
                                    activeStep={activeStep}
                                />
                            </div>
                            <div className="stepform-content d-flex flex-column justify-content-center flex-grow-1 two-space">
                                <div className='step-form-content-caption'>
                                    { getSectionComponent()  }
                                    
                                </div>
                                { (activeStep === 0) &&
                                    <div className='row g-sm-5 g-4'>
                                        <div className='col-12'>
                                            <div className='step-radio-btn-bar step-radio-btn-bar mt-lg-1 mt-4'>
                                                <div className='radio-box d-flex align-items-center label-radio-btn gap-2'>
                                                    <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2">
                                                        <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' data-bs-toggle="modal" data-bs-target="#Nationality_Sorry_modal" value="" id="yes_radio" />
                                                        <label className="form-check-label grey-radio-btn" for='yes_radio'>
                                                            <div className='label-caption d-flex align-items-center gap-2'>
                                                                <div className='icon-bar'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                                                                        <path d="M5.07904 8.94848L1.52576 5.3952L0 6.92096L5.07904 12L15.5533 1.52576L14.0275 0L5.07904 8.94848Z" fill="#808080"/>
                                                                    </svg>
                                                                </div>
                                                                <span>Yes</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <button className="form-check mb-0 d-flex align-items-center ps-0 gap-2 border-0 bg-transparent" onClick={ScrollTopBtn}>
                                                        <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="no_radio" onClick={ () => setActiveStep(activeStep + 1) } />
                                                        <label className="form-check-label blue-radio-btn" for='no_radio'>
                                                            <div className='label-caption d-flex align-items-center gap-2'>
                                                                <div className='icon-bar'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.772893 0.21967C1.06579 -0.0732233 1.54066 -0.0732233 1.83355 0.21967L12.3336 10.7197C12.6264 11.0126 12.6264 11.4874 12.3336 11.7803C12.0407 12.0732 11.5658 12.0732 11.2729 11.7803L0.772893 1.28033C0.479999 0.987437 0.479999 0.512563 0.772893 0.21967Z" fill="#007BFF"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3336 0.21967C12.6264 0.512563 12.6264 0.987437 12.3336 1.28033L1.83355 11.7803C1.54066 12.0732 1.06579 12.0732 0.772893 11.7803C0.479999 11.4874 0.479999 11.0126 0.772893 10.7197L11.2729 0.21967C11.5658 -0.0732233 12.0407 -0.0732233 12.3336 0.21967Z" fill="#007BFF"/>
                                                                    </svg>
                                                                </div>
                                                                <span>No</span> 
                                                            </div>
                                                        </label>
                                                    </button>           
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='step-form-btn-bar'>
                                    <div style={{display: 'flex', gap: '5px', marginTop: '20px' }}>
                                        { (activeStep !== 0 && activeStep !== steps.length - 1)
                                            && <button className='btn bg-transparent border-primary py-1 px-2' onClick={PrevStepBtn}>Previous</button>
                                        }
                                        { activeStep !== steps.length - 1
                                        && <button className='btn bg-primary py-1 px-2 text-white' onClick={NextStepBtn}>Next</button>
                                        }
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )

}