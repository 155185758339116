import React from 'react';

// <------- section components start --------->
import StepBanner from "./StepBanner";
// <------- section components end --------->

// <------- img start --------->
import StepbannerImgItem from "../../img/login-step-banner.jpg";
// <------- img end --------->


export default function LoginStep(props) {
 

    return(
        <>
           <div className='login-step-block'>
           <StepBanner
                StepHeading= "Connect to Pangea" 
                MainHeading= "Login so we can issue LEOS to your account" 
                Textdescription= {["You will be redirected to the login page for UNITED Wallet." , <br/>, <br/>, "Please create a UNITED Wallet account if you have not created one."]}
                StepBtnUrl= "/"
                StepBtnName= "Login with UNITED Wallet"
                HelpBtnUrl= "https://pangea-web4-world.webflow.io/contact-us"
                helpBtnName= "Help"
                StepbannerImg= {StepbannerImgItem}
            />
           </div>
           
       
        </>
    )

}