import React from "react";


export default function TokenInfo(props) {

    return(
        <>
            <p>{props.Name}</p>
            <span>{props.Answer}</span>
        </>
    )

}