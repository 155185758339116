import React from 'react';
// import { Link } from "react-router-dom";



export default function StepBanner(props) {
   
    
    // const NoNext = () => {
    //     console.log("this click");
    //   }

    const ScrollTopBtn = () => {
        window.scrollTo(0, 0);
    }   


    return(
        
        <>
            <div className='step-banner-block'>
                <div className='section-bg'>
                    <div className='container px-0'>
                        <div className='row g-sm-5 g-4 align-items-center'>
                            <div className='col-md-6 col-12'>
                                <div className='caption-box d-flex flex-column'>
                                    <h3>{props.StepHeading}</h3>
                                    <h2>{props.MainHeading}</h2>
                                    <p>{props.Textdescription}</p>
                                    <div className='listing-item-box d-none'>
                                        <ul className='d-flex flex-column gap-2 m-0 p-0'>
                                            <li>North Korea </li>
                                            <li>USA</li>
                                            <li>Iran</li>
                                        </ul>
                                    </div>
                                    <div className='step-link-btn-bar'>
                                        <div className='d-flex flex-sm-row flex-column align-itans-center gap-sm-3 gap-2'>
                                            {/* <Link to={props.StepBtnUrl} target="_blank" className="fill-btn d-flex flex-grow-1 allign-items-center gap-1" onClick={ScrollTopBtn}>
                                                <span>{props.StepBtnName}</span>
                                                <div className='icon-bar'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 10.5105C1.75 12.8311 2.67187 15.0567 4.31282 16.6977C5.95376 18.3386 8.17936 19.2605 10.5 19.2605C12.8206 19.2605 15.0462 18.3386 16.6872 16.6977C18.3281 15.0567 19.25 12.8311 19.25 10.5105C19.25 8.18985 18.3281 5.96426 16.6872 4.32331C15.0462 2.68237 12.8206 1.7605 10.5 1.7605C8.17936 1.7605 5.95376 2.68237 4.31282 4.32331C2.67187 5.96426 1.75 8.18985 1.75 10.5105ZM20.5 10.5105C20.5 13.1627 19.4464 15.7062 17.5711 17.5816C15.6957 19.4569 13.1522 20.5105 10.5 20.5105C7.84784 20.5105 5.3043 19.4569 3.42893 17.5816C1.55357 15.7062 0.5 13.1627 0.5 10.5105C0.5 7.85833 1.55357 5.31479 3.42893 3.43943C5.3043 1.56407 7.84784 0.510498 10.5 0.510498C13.1522 0.510498 15.6957 1.56407 17.5711 3.43943C19.4464 5.31479 20.5 7.85833 20.5 10.5105ZM7.8175 14.0142C7.70031 14.1316 7.54129 14.1976 7.37544 14.1977C7.20959 14.1978 7.05048 14.1321 6.93313 14.0149C6.81577 13.8977 6.74977 13.7387 6.74965 13.5728C6.74954 13.407 6.81531 13.2479 6.9325 13.1305L12.0538 8.0105H8.59375C8.42799 8.0105 8.26902 7.94465 8.15181 7.82744C8.0346 7.71023 7.96875 7.55126 7.96875 7.3855C7.96875 7.21974 8.0346 7.06077 8.15181 6.94356C8.26902 6.82635 8.42799 6.7605 8.59375 6.7605H13.5625C13.7283 6.7605 13.8872 6.82635 14.0044 6.94356C14.1217 7.06077 14.1875 7.21974 14.1875 7.3855V12.3542C14.1875 12.52 14.1217 12.679 14.0044 12.7962C13.8872 12.9134 13.7283 12.9792 13.5625 12.9792C13.3967 12.9792 13.2378 12.9134 13.1206 12.7962C13.0033 12.679 12.9375 12.52 12.9375 12.3542V8.89425L7.8175 14.0142Z" fill="white"/>
                                                </svg>
                                                </div>
                                            </Link>
                                            <Link to={props.HelpBtnUrl} target="_blank" className="fill-btn border-btn d-flex allign-items-center gap-1" onClick={ScrollTopBtn}>
                                                <span>{props.helpBtnName}</span>
                                                <div className='icon-bar'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                        <path d="M10 1.85665C12.3125 1.85665 14.4856 2.75569 16.1202 4.39031C17.7548 6.02492 18.6538 8.198 18.6538 10.5105C18.6538 12.823 17.7548 14.9961 16.1202 16.6307C14.4856 18.2653 12.3125 19.1643 10 19.1643C7.6875 19.1643 5.51442 18.2653 3.87981 16.6307C2.24519 14.9961 1.34615 12.823 1.34615 10.5105C1.34615 8.198 2.24519 6.02492 3.87981 4.39031C5.51442 2.75569 7.6875 1.85665 10 1.85665ZM10 0.510498C4.47596 0.510498 0 4.98646 0 10.5105C0 16.0345 4.47596 20.5105 10 20.5105C15.524 20.5105 20 16.0345 20 10.5105C20 4.98646 15.524 0.510498 10 0.510498Z" fill="black"/>
                                                        <path d="M10.0337 5.89502C11.8366 5.89502 13.0769 6.89502 13.0769 8.33252C13.0769 9.28444 12.6154 9.9431 11.726 10.4671C10.8894 10.9527 10.6058 11.3085 10.6058 11.9239V12.3037H8.93752L8.9231 11.8902C8.84137 10.8998 9.18752 10.2844 10.0577 9.77483C10.8702 9.28925 11.2116 8.98156 11.2116 8.3854C11.2116 7.78925 10.6346 7.35175 9.91829 7.35175C9.19233 7.35175 8.66829 7.8229 8.62983 8.53444H6.9231C6.95675 6.98637 8.10098 5.89502 10.0337 5.89502ZM8.76925 14.1354C8.76925 13.5825 9.23079 13.145 9.7981 13.145C10.3702 13.145 10.8317 13.5777 10.8317 14.1354C10.8317 14.6931 10.3702 15.1258 9.7981 15.1258C9.22598 15.1258 8.76925 14.6931 8.76925 14.1354Z" fill="black"/>
                                                    </svg>
                                                </div>
                                            </Link> */}
                                            <button className="fill-btn d-flex flex-grow-1 allign-items-center gap-1">
                                                <span>{props.StepBtnName}</span>
                                                <div className='icon-bar'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 10.5105C1.75 12.8311 2.67187 15.0567 4.31282 16.6977C5.95376 18.3386 8.17936 19.2605 10.5 19.2605C12.8206 19.2605 15.0462 18.3386 16.6872 16.6977C18.3281 15.0567 19.25 12.8311 19.25 10.5105C19.25 8.18985 18.3281 5.96426 16.6872 4.32331C15.0462 2.68237 12.8206 1.7605 10.5 1.7605C8.17936 1.7605 5.95376 2.68237 4.31282 4.32331C2.67187 5.96426 1.75 8.18985 1.75 10.5105ZM20.5 10.5105C20.5 13.1627 19.4464 15.7062 17.5711 17.5816C15.6957 19.4569 13.1522 20.5105 10.5 20.5105C7.84784 20.5105 5.3043 19.4569 3.42893 17.5816C1.55357 15.7062 0.5 13.1627 0.5 10.5105C0.5 7.85833 1.55357 5.31479 3.42893 3.43943C5.3043 1.56407 7.84784 0.510498 10.5 0.510498C13.1522 0.510498 15.6957 1.56407 17.5711 3.43943C19.4464 5.31479 20.5 7.85833 20.5 10.5105ZM7.8175 14.0142C7.70031 14.1316 7.54129 14.1976 7.37544 14.1977C7.20959 14.1978 7.05048 14.1321 6.93313 14.0149C6.81577 13.8977 6.74977 13.7387 6.74965 13.5728C6.74954 13.407 6.81531 13.2479 6.9325 13.1305L12.0538 8.0105H8.59375C8.42799 8.0105 8.26902 7.94465 8.15181 7.82744C8.0346 7.71023 7.96875 7.55126 7.96875 7.3855C7.96875 7.21974 8.0346 7.06077 8.15181 6.94356C8.26902 6.82635 8.42799 6.7605 8.59375 6.7605H13.5625C13.7283 6.7605 13.8872 6.82635 14.0044 6.94356C14.1217 7.06077 14.1875 7.21974 14.1875 7.3855V12.3542C14.1875 12.52 14.1217 12.679 14.0044 12.7962C13.8872 12.9134 13.7283 12.9792 13.5625 12.9792C13.3967 12.9792 13.2378 12.9134 13.1206 12.7962C13.0033 12.679 12.9375 12.52 12.9375 12.3542V8.89425L7.8175 14.0142Z" fill="white"/>
                                                </svg>
                                                </div>
                                            </button>
                                            <button className="fill-btn border-btn d-flex allign-items-center gap-1" onClick={ScrollTopBtn}>
                                                <span>{props.helpBtnName}</span>
                                                <div className='icon-bar'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                        <path d="M10 1.85665C12.3125 1.85665 14.4856 2.75569 16.1202 4.39031C17.7548 6.02492 18.6538 8.198 18.6538 10.5105C18.6538 12.823 17.7548 14.9961 16.1202 16.6307C14.4856 18.2653 12.3125 19.1643 10 19.1643C7.6875 19.1643 5.51442 18.2653 3.87981 16.6307C2.24519 14.9961 1.34615 12.823 1.34615 10.5105C1.34615 8.198 2.24519 6.02492 3.87981 4.39031C5.51442 2.75569 7.6875 1.85665 10 1.85665ZM10 0.510498C4.47596 0.510498 0 4.98646 0 10.5105C0 16.0345 4.47596 20.5105 10 20.5105C15.524 20.5105 20 16.0345 20 10.5105C20 4.98646 15.524 0.510498 10 0.510498Z" fill="black"/>
                                                        <path d="M10.0337 5.89502C11.8366 5.89502 13.0769 6.89502 13.0769 8.33252C13.0769 9.28444 12.6154 9.9431 11.726 10.4671C10.8894 10.9527 10.6058 11.3085 10.6058 11.9239V12.3037H8.93752L8.9231 11.8902C8.84137 10.8998 9.18752 10.2844 10.0577 9.77483C10.8702 9.28925 11.2116 8.98156 11.2116 8.3854C11.2116 7.78925 10.6346 7.35175 9.91829 7.35175C9.19233 7.35175 8.66829 7.8229 8.62983 8.53444H6.9231C6.95675 6.98637 8.10098 5.89502 10.0337 5.89502ZM8.76925 14.1354C8.76925 13.5825 9.23079 13.145 9.7981 13.145C10.3702 13.145 10.8317 13.5777 10.8317 14.1354C10.8317 14.6931 10.3702 15.1258 9.7981 15.1258C9.22598 15.1258 8.76925 14.6931 8.76925 14.1354Z" fill="black"/>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='subscribe-marketing-checkbox d-none'>
                                        <div className="form-check square-tick-check mb-0 d-flex ps-0 gap-2">
                                            <input className="form-check-input m-0" type="checkbox" value="" id="subscribe_marketing" />
                                            <label className="form-check-label" for="subscribe_marketing">
                                            Yes, I'd like to receive marketing updates about Pangea and LEOS! By checking this box, I agree to receive marketing communications, updates, and special offers via email from Pangea. I understand I can unsubscribe at any time and acknowledge that my information will be handled in accordance with Pangea's Privacy Policy.
                                            </label>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div className='col-md-6 col-12 step-banner-imgblock'>
                                <div className='step-banner-imgbar'>
                                    <img src={props.StepbannerImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}