import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";


export default function BuyLEOSBox() {

    const [selected, setSelected] = useState("");

    return(
        <>
            <div className="item-box buy-leos-box d-flex flex-column gap-sm-4 gap-3 rounded-3 p-sm-4 p-3 Ocean_Blue_bg">
                <h3 className="text capitalize text-white mb-2">Buy LEOS Ƚ</h3>
                <div className="form-bar">
                    <form action="" method="" className="buy-leos-form-block" id="buy_leos_form">
                        <div className="row gy-sm-4 gy-3">
                            <div className="col-12">
                                <div className="input-box d-flex flex-column gap-2">
                                    <label className="m-0 s-w" htmlFor="">Enter Amount</label>
                                    <div className="input-group px-sm-3 px-2">
                                        <span className="input-group-text p-0 bg-transparent border-0 number-text text-white">$</span>
                                        <input type="number" className="form-control bg-transparent border-0" />
                                        <span className="input-group-text p-0 bg-transparent border-0">
                                        <ReactFlagsSelect
                                           selected={selected}
                                            onSelect={(code) => setSelected(code)}
                                            countries={["AU", "US", "IN"]}
                                            customLabels={{"AU": "AUD", "US": "USD", "IN": "INR",}}
                                            className="flag-dropdown"
                                            selectButtonClassName="selected-flag-btn"
                                            placeholder="Currency"
                                        />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="convert-leos-capton d-flex flex-column align-items-center text-center gap-4">
                                    <div className="icon-bar">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                            <path d="M27.5 20L17.5 10M17.5 10L7.5 20M17.5 10V50M32.5 40L42.5 50M42.5 50L52.5 40M42.5 50V10" stroke="white" stroke-width="3.125" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="text-caption d-flex flex-column gap-1">
                                        <p className="text-white number-text convert-leos-text"><span className="text-white number-text">1000</span>Ƚ</p>
                                        <p className="s-w">Total LEOS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btn-bar">
                                    <Link to="/buyleosstep" className="fill-btn border-btn bg-white">
                                        pay
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}