import React from "react";
import { Link } from "react-router-dom";


export default function ShadowBox(props) {

    return(
        <>
            <div className="shadow-box d-flex flex-column align-items-center gap-md-4 gap-3">
                <span className="title">{props.ContractTitle}</span>
                <div className="btn-bar d-flex align-itans-center gap-2">
                    <Link to={props.TokenUrl} target="_blank" className="fill-btn Forest_Green_bg">{props.TokenName}</Link>
                    <Link to={props.GithubUrl} target="_blank" className="fill-btn border-btn">Github</Link>
                </div>
            </div>
        </>
    )

}