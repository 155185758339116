import React from 'react';
import { useNavigate  } from "react-router-dom";

// <------- section components start --------->
import StepBanner from "./StepBanner";
// <------- section components end --------->

// <------- img start --------->
import StepbannerImgItem from "../../img/nationality-step-banner.jpg";
// <------- img end --------->




export default function NationalityStep(props) {

    let navigate = useNavigate(); 
    const CloseModal = () => {
        let path = `/`; 
        navigate(path);
    }       

    
    
        return(
            <>
               <div className='nationality-step-block'>
                    <StepBanner
                        StepHeading= "Nationality" 
                        MainHeading= "Are you a resident and/or citizen of any of the following countries?" 
                        Textdescription= ""
                        StepBtnUrl= "/"
                        StepBtnName= "Login with UNITED Wallet"
                        HelpBtnUrl= "https://pangea-web4-world.webflow.io/contact-us"
                        helpBtnName= "Help"
                        StepbannerImg= {StepbannerImgItem}
                    />
               </div>
               

                {/* <--------- nationality sorry modal start ----------> */}
                <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="Nationality_Sorry_modal"  tabindex="-1" aria-labelledby="Nationality_Sorry_modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='modal-body-caption d-flex flex-column align-items-center text-center gap-4'>
                                    <h3>Sorry!</h3>
                                    <p>LEOS purchases are not yet available in your country due to regulatory restrictions.</p>
                                    <div className='btn-bar'>
                                        <button className="fill-btn d-flex flex-grow-1 Ocean_Blue_bg allign-items-center gap-1" data-bs-dismiss="modal" onClick={CloseModal}>
                                            <div className='icon-bar'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1872 21.1871C22.8281 19.5462 23.75 17.3206 23.75 15C23.75 12.6793 22.8281 10.4537 21.1872 8.81277C19.5462 7.17183 17.3206 6.24996 15 6.24996C12.6794 6.24996 10.4538 7.17183 8.81282 8.81277C7.17187 10.4537 6.25 12.6793 6.25 15C6.25 17.3206 7.17187 19.5462 8.81282 21.1871C10.4538 22.8281 12.6794 23.75 15 23.75C17.3206 23.75 19.5462 22.8281 21.1872 21.1871ZM7.92893 7.92889C9.8043 6.05352 12.3478 4.99995 15 4.99995C17.6522 4.99995 20.1957 6.05352 22.0711 7.92889C23.9464 9.80425 25 12.3478 25 15C25 17.6521 23.9464 20.1957 22.0711 22.071C20.1957 23.9464 17.6522 25 15 25C12.3478 25 9.8043 23.9464 7.92893 22.071C6.05357 20.1957 5 17.6521 5 15C5 12.3478 6.05357 9.80425 7.92893 7.92889ZM19.3743 14.4192C19.5402 14.4191 19.6993 14.4849 19.8167 14.6021C19.934 14.7193 20 14.8783 20.0001 15.0441C20.0002 15.21 19.9345 15.3691 19.8173 15.4865C19.7001 15.6038 19.5411 15.6698 19.3752 15.6699L12.1336 15.6691L14.5802 18.1156C14.6974 18.2329 14.7632 18.3918 14.7632 18.5576C14.7632 18.7233 14.6974 18.8823 14.5802 18.9995C14.4629 19.1167 14.304 19.1826 14.1382 19.1826C13.9725 19.1826 13.8135 19.1167 13.6963 18.9995L10.1828 15.4861C10.0656 15.3689 9.99978 15.2099 9.99978 15.0441C9.99978 14.8784 10.0656 14.7194 10.1828 14.6022L13.6963 11.0888C13.8135 10.9716 13.9725 10.9057 14.1382 10.9057C14.304 10.9057 14.4629 10.9716 14.5802 11.0888C14.6974 11.206 14.7632 11.365 14.7632 11.5307C14.7632 11.6965 14.6974 11.8554 14.5802 11.9727L12.1336 14.4192L19.3743 14.4192Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <span>back to home</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <--------- nationality sorry modal end ----------> */}
            
            </>
        )

}