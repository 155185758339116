import React from 'react';
import { Stepper } from 'react-form-stepper';

  
export default function StepStructure(props) {


    return(
        <>
            <Stepper
            { ...props }
                connectorStateColors={true}
                connectorStyleConfig={{
                completedColor: '#4CAF50',
                activeColor: '#4CAF50',
                disabledColor: '#eee',
                
            }}
            styleConfig={{
                activeBgColor: '#000000',
                completedBgColor: '#4CAF50',
                inactiveBgColor: '#F3F3F3',
                activeTextColor: '#ffffff',
                completedTextColor: '#ffffff',
                inactiveTextColor: '#444',
            }}
        />
        </>
    )

}