import React from 'react';
import { useEffect } from 'react';

const UseDocumentTitle = (title) => {

    useEffect(() => {
        document.title = title;
    }, [title]);

    return(
        <>

        </>
    )

}

export default UseDocumentTitle;