import React, { useState  } from 'react';
import { Link } from "react-router-dom";
import iDEALLOGO from "../../img/IDEAL_Logo.png"
import SepaLOGO from "../../img/sepa.png"
import GooglePayLOGO from "../../img/google-pay-icon.png"

export default function PaymentStep() {

    const [isActive, setActive] = useState(false);
    

    function handleSubmit(e) {
        if(e.target.id === 'card_payment_btn' || e.target.id === 'crypto_payment_btn'){
            setActive(true);
        }else{
            setActive(false);
        }
    }

    return(
        <>
            <div className='payment-step-block'>
                <div className='payment-step-box d-flex flex-column'>
                    <ul className='amount-listing d-flex flex-column m-0 p-0'>
                        <li className='d-flex justify-content-between align-items-center'>
                            <span className='label'>Amount</span>
                            <p className='price number-text'>$1000.00 AUD</p>
                        </li>
                        <li className='d-flex justify-content-between align-items-center'>
                            <span className='label'>Total LEOS</span>
                            <p className='price number-text'>1000.00Ƚ</p>
                        </li>
                    </ul>
                    <div className='payment-option-bar d-flex flex-column gap-3'>
                        <span className='heading'>Select Payment option</span>
                            <div className='payment-option-btn radio-box flex-grow-1 label-radio-btn d-flex flex-sm-row flex-column flex-lg-nowrap flex-wrap gap-2'>
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 payment-option-item">
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="ideal_payment_btn" />
                                    <label className="form-check-label w-100 position-relative" for='ideal_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2 payment-method-box'>
                                            <div className='d-flex align-items-cneter gap-1 w-100'>
                                                <div className='img-bar'>
                                                    <img src={iDEALLOGO} alt="" />
                                                </div>
                                                <p className='name'>iDEAL</p>
                                            </div>
                                            <p className='fee-text text-nowrap'>(1.5% fee)</p>
                                        </div>
                                    </label>
                                </div>
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 payment-option-item">
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="card_payment_btn" />
                                    <label className="form-check-label w-100 position-relative" for='card_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2 payment-method-box'>
                                            <div className='d-flex align-items-cneter gap-1 w-100'>
                                                <div className='img-bar'>
                                                <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3571 8.47342H0V5.39307H21.3571V8.47342Z" fill="black"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69643 2.10721C2.56227 2.10721 1.64286 3.02663 1.64286 4.16078V14.8394C1.64286 15.9735 2.56227 16.8929 3.69643 16.8929H19.3036C20.4377 16.8929 21.3571 15.9735 21.3571 14.8394V4.16078C21.3571 3.02663 20.4377 2.10721 19.3036 2.10721H3.69643ZM0 4.16078C0 2.1193 1.65495 0.464355 3.69643 0.464355H19.3036C21.3451 0.464355 23 2.1193 23 4.16078V14.8394C23 16.8808 21.3451 18.5358 19.3036 18.5358H3.69643C1.65495 18.5358 0 16.8808 0 14.8394V4.16078Z" fill="black"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10742 12.4777C4.10742 11.6271 4.79698 10.9375 5.6476 10.9375H8.11189C8.9625 10.9375 9.65206 11.6271 9.65206 12.4777V13.5045C9.65206 14.3551 8.9625 15.0446 8.11189 15.0446H5.6476C4.79698 15.0446 4.10742 14.3551 4.10742 13.5045V12.4777Z" fill="black"/>
                                                </svg>

                                                </div>
                                                <p className='name'>Cards</p>
                                            </div>
                                            <p className='fee-text text-nowrap'>(2.9% fee)</p>
                                        </div>
                                    </label>
                                </div>  
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 payment-option-item">
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="sepa_payment_btn" />
                                    <label className="form-check-label w-100 position-relative" for='sepa_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2 payment-method-box'>
                                            <div className='d-flex align-items-cneter gap-1 w-100'>
                                                <div className='img-bar'>
                                                    <img src={SepaLOGO} alt="" />
                                                </div>
                                                <p className='name'>SEPA</p>
                                            </div>
                                            <p className='fee-text text-nowrap'>(2.5% fee)</p>
                                        </div>
                                    </label>
                                </div>
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 payment-option-item">
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="googlepay_payment_btn" />
                                    <label className="form-check-label w-100 position-relative" for='googlepay_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2 payment-method-box'>
                                            <div className='d-flex align-items-cneter gap-1 w-100'>
                                                <div className='img-bar'>
                                                    <img src={GooglePayLOGO} alt="" />
                                                </div>
                                                <p className='name'>Google Pay</p>
                                            </div>
                                            <p className='fee-text text-nowrap'>(1.5% fee)</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        <div className='payment-option-bar d-flex flex-md-row flex-column gap-md-3 gap-2'>
                            <div className='payment-option-btn radio-box flex-grow-1 label-radio-btn d-flex flex-column gap-2'>
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100" >
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="card_payment_btn" onClick={handleSubmit} />
                                    <label className="form-check-label w-100 position-relative" for='card_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8571 8.97342H0.5V5.89307H21.8571V8.97342Z" fill="black"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19643 2.60721C3.06227 2.60721 2.14286 3.52663 2.14286 4.66078V15.3394C2.14286 16.4735 3.06227 17.3929 4.19643 17.3929H19.8036C20.9377 17.3929 21.8571 16.4735 21.8571 15.3394V4.66078C21.8571 3.52663 20.9377 2.60721 19.8036 2.60721H4.19643ZM0.5 4.66078C0.5 2.6193 2.15495 0.964355 4.19643 0.964355H19.8036C21.8451 0.964355 23.5 2.6193 23.5 4.66078V15.3394C23.5 17.3808 21.8451 19.0358 19.8036 19.0358H4.19643C2.15495 19.0358 0.5 17.3808 0.5 15.3394V4.66078Z" fill="black"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60693 12.9777C4.60693 12.1271 5.2965 11.4375 6.14711 11.4375H8.6114C9.46202 11.4375 10.1516 12.1271 10.1516 12.9777V14.0045C10.1516 14.8551 9.46202 15.5446 8.6114 15.5446H6.14711C5.2965 15.5446 4.60693 14.8551 4.60693 14.0045V12.9777Z" fill="black"/>
                                                </svg>
                                            </div>
                                            <span>Pay with card</span>
                                            <div className='checked-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                                                    <path d="M4.25206 7.45706L1.291 4.496L0.0195312 5.76747L4.25206 10L12.9806 1.27147L11.7091 0L4.25206 7.45706Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 ">
                                    <input className="form-check-input m-0" type="radio" name='Nationality-confirm-btn' value="" id="crypto_payment_btn" onClick={handleSubmit} />
                                    <label className="form-check-label w-100 position-relative" for='crypto_payment_btn'>
                                        <div className='label-caption d-flex align-items-center justify-content-center text-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                                                    <path d="M12 0.524902C6.2845 0.524902 0.5 2.3028 0.5 5.6999V10.2999C0.5 13.697 6.2845 15.4749 12 15.4749C17.7155 15.4749 23.5 13.697 23.5 10.2999V5.6999C23.5 2.3028 17.7155 0.524902 12 0.524902ZM2.8 10.2999V8.92335C3.52834 9.33583 4.29959 9.66747 5.1 9.91235V12.0755C3.5912 11.4844 2.8 10.7806 2.8 10.2999ZM16.6 10.4701V12.7368C15.603 12.9426 14.4529 13.0933 13.15 13.1485V10.8485C14.3074 10.8024 15.4601 10.6759 16.6 10.4701ZM10.85 13.1496C9.69081 13.1052 8.53708 12.9675 7.4 12.7379V10.4713C8.50285 10.6714 9.6678 10.8013 10.85 10.8508V13.1496ZM18.9 12.0755V9.91235C19.7006 9.66797 20.4719 9.33631 21.2 8.92335V10.2999C21.2 10.7806 20.41 11.4844 18.9 12.0755ZM12 8.5749C6.04645 8.5749 2.8 6.67625 2.8 5.6999C2.8 4.72355 6.04645 2.8249 12 2.8249C17.9536 2.8249 21.2 4.72355 21.2 5.6999C21.2 6.67625 17.9536 8.5749 12 8.5749Z" fill="black"/>
                                                </svg>
                                            </div>
                                            <span>Pay with crypto</span> 
                                            <div className='checked-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                                                    <path d="M4.25206 7.45706L1.291 4.496L0.0195312 5.76747L4.25206 10L12.9806 1.27147L11.7091 0L4.25206 7.45706Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </label>
                                </div>   
                            </div>
                            <div className='payment-option-info'>
                                <ul className='info-payemnt-listing d-flex flex-column gap-1 m-0 p-0'>
                                    <li>Credit Card (2.9% fee)</li>
                                    <li>Ethereum (0%)</li>
                                    <li>Direct Debit (0.2%)</li>
                                    <li>SEPA Transfer (80c)</li>
                                </ul>
                            </div>
                        </div>
                        <div className={isActive ? 'payment-btn d-block': 'payment-btn d-none'} >
                            <Link to="/paymentsuccessful" className="fill-btn Digital_Gold_bg">PAY $1000.00 </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}