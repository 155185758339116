import React from 'react';

// <------- section components start --------->
import StepBanner from "./StepBanner";
// <------- section components end --------->

// <------- img start --------->
import StepbannerImgItem from "../../img/verify-step-banner.jpg";
// <------- img end --------->


export default function IdentityVerificationStep(props) {
 

    return(
        <>
           <div className='identify-verification-step-block'>
            <StepBanner
                    StepHeading= "Identity Verification" 
                    MainHeading= "All LEOS customers must verify their identity" 
                    Textdescription= {["This is required to ensure regulatory compliance. And ensure that the Tonomy Foundation does not get its bank accounts closed - important so that we can build Pangea for you!"]}
                    StepBtnUrl= "/"
                    StepBtnName= "Identity Verification with veriff.com"
                    HelpBtnUrl= "https://pangea-web4-world.webflow.io/contact-us"
                    helpBtnName= "Help"
                    StepbannerImg= {StepbannerImgItem}
                />
           </div>
       
        </>
    )

}